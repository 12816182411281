import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";

const visits = ["C1D1", "C1D8", "C1D15", "C2D1", "C2D15", "EOT"];
const destinations = ["CDL", "3rd Part Lab", "Internal Lab"];
const shippingTemperatures = ["Room Temperature", "4°C", "Dry Ice"];

export const ModalAnalysis = ({ visible, onClose, studyId, studyCode }) => {
  const { t } = useTranslation();
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [selectedVisits, setSelectedVisits] = React.useState([]);
  const [destination, setDestination] = React.useState("");
  const [shippingTemperature, setShippingTemperature] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [created, setCreated] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      name &&
      description &&
      selectedVisits.length > 0 &&
      destination &&
      shippingTemperature
    ) {
      setIsLoading(true);
      setTimeout(() => {
        setName("");
        setDescription("");
        setSelectedVisits([]);
        setDestination("");
        setShippingTemperature("");
        setCreated(true);
        setIsLoading(false);
        onClose(true);
      }, 1000); // Simulate a network request
    } else {
      setErrorMessage(t("pleaseFillAllRequiredFields"));
    }
  };

  React.useEffect(() => {
    if (created) {
      setCreated(false);
      alert(t("analysisCreatedSuccess"));
    }
  }, [created, t]);

  return (
    <Modal open={visible}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <IconButton
          style={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
          onClick={() => {
            onClose(false);
          }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              {t("createAnalysis")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              {t("pleaseIndicateFields")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                name="name"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                id="input-name"
                label={t("name")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                name="description"
                value={description}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
                id="input-description"
                label={t("description")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="visits-label">{t("visits")}</InputLabel>
              <Select
                labelId="visits-label"
                id="visits"
                multiple
                value={selectedVisits}
                onChange={(event) => setSelectedVisits(event.target.value)}
                input={<OutlinedInput label={t("visits")} />}
                renderValue={(selected) => selected.join(", ")}
              >
                {visits.map((visit) => (
                  <MenuItem key={visit} value={visit}>
                    <Checkbox checked={selectedVisits.indexOf(visit) > -1} />
                    <ListItemText primary={visit} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="destination-label">{t("destination")}</InputLabel>
              <Select
                labelId="destination-label"
                label={t("destination")}
                id="destination"
                name="destination"
                value={destination}
                onChange={(event) => setDestination(event.target.value)}
                required
              >
                {destinations.map((dest) => (
                  <MenuItem key={dest} value={dest}>
                    {dest}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="shipment-temperature-label">
                {t("shippingTemperature")}
              </InputLabel>
              <Select
                labelId="shipment-temperature-label"
                label={t("shippingTemperature")}
                id="shippingtemperature"
                name="shippingTemperature"
                value={shippingTemperature}
                onChange={(event) => setShippingTemperature(event.target.value)}
                required
              >
                {shippingTemperatures.map((temp) => (
                  <MenuItem key={temp} value={temp}>
                    {temp}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="study-label">{t("study")}</InputLabel>
              <Select
                labelId="study-label"
                id="study-select"
                value={studyCode}
                input={<OutlinedInput label={t("study")} />}
                disabled
              >
                <MenuItem value={studyCode}>{studyCode}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ mt: 3, mb: 2, padding: 2 }}
            >
              {t("create")}
            </Button>
          </Grid>
        </Grid>
        {errorMessage && (
          <p
            style={{
              color: "#721c24",
              backgroundColor: "#f8d7da",
              borderColor: "#f5c6cb",
              padding: ".75rem 1.25rem",
              marginBottom: "1rem",
              border: "1px solid transparent",
              borderRadius: ".25rem",
            }}
          >
            {errorMessage}
          </p>
        )}
      </Box>
    </Modal>
  );
};