import jsPDF from "jspdf";
import "jspdf-autotable";
import { addLogoToPdf } from "./utils/PdfUtils";
import html2canvas from "html2canvas";

export const printSampleShipmentOrder = (
  data,
) => {
  const pdf = new jsPDF("p", "mm", "a4"); // A4 size page of PDF
  const logoHeight = addLogoToPdf(pdf);
  const pageWidth = pdf.internal.pageSize.getWidth();

  const pageCenter = pdf.internal.pageSize.getWidth() / 2;
  pdf.setFontSize(12);
  const text = "Sample Shipment order form";
  const textHeight = 12;
  const y = logoHeight + 30;
  const leftMargin = 10;
  const rectWidth = pageWidth - leftMargin * 2;

  pdf.setFillColor(173, 216, 230); 
  pdf.rect(leftMargin, y - textHeight, rectWidth, textHeight, "F"); 

  const textY = y - textHeight + pdf.internal.getLineHeight() / 2;
  pdf.text(text.toUpperCase(), pageCenter, textY, { align: "center" });

  pdf.setFontSize(10);

  // Address
  pdf.text("Address:", leftMargin, y + 10);
  pdf.text(data.address, leftMargin, y + 20);

  // patient section
  const input = document.getElementById("patient-section");
  console.log(input);

  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(imgData, "PNG", 0, y + 30, pdfWidth, pdfHeight);
    pdf.save(`sample_shipment_order_${data.shipmentNumber}.pdf`);
  });
};