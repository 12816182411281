import React from "react";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel } from "@mui/material";
import { Checkbox, ListItemText } from "@mui/material";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Box, Grid, Paper, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomDatePicker from "../../ui/utils/CustomDatePicker";
import { MenuItem, Select } from "@mui/material";
import UserInfo from "../../ui/UserInfo";
import { store } from "../../../store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button } from "@mui/material";
import AddressForm from "../../ui/utils/AddressForm";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { getCenterData } from "../../../redux/actions/center";

const analyses = [
  {
    id: 1,
    name: "PK for Irinotecan",
    visitIds: ["C1D1", "C1D3", "C1D15", "Additional C1D14"],
    shippingTemperature: "Dry Ice",
    description: "1 tube /visit",
    destination: "TPL",
    primary: true,
  },
  {
    id: 2,
    name: "PK for Oxaliplatin",
    visitIds: ["C1D1", "C1D3", "C1D15", "Additional C1D14"],
    shippingTemperature: "4°C",
    description: "2 tubes per timepoint",
    destination: "CDL",
    primary: false,
  },
  {
    id: 3,
    name: "Plasma",
    visitIds: ["C1D9", "C2D1", "C4D1"],
    shippingTemperature: "Room Temperature",
    description: "1 tube /visit",
    destination: "TPL",
    primary: true,
  },
  {
    id: 4,
    name: "PBMC",
    visitIds: ["C1D9", "C2D1", "C4D1"],
    shippingTemperature: "Dry Ice",
    description: "2 tubes per timepoint",
    destination: "CDL",
    primary: true,
  },
  {
    id: 5,
    name: "MRD",
    visitIds: ["C1D9", "C2D1", "C4D1"],
    shippingTemperature: "4°C",
    description: "1 tube /visit",
    destination: "TPL",
    primary: true,
  },
  {
    id: 6,
    name: "BBMC",
    visitIds: ["C1D9", "C2D1", "C4D1"],
    shippingTemperature: "Room Temperature",
    description: "2 tubes per timepoint",
    destination: "CDL",
    primary: true,
  },
];

export const ScreenCreateSampleShipmentForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.user);
  const centerNumber = location.state?.centerNumber;
  const centerId = location.state?.centerId;
  const center = useSelector((state) => state.center.center);
  const [hospital, setHospital] = React.useState("");
  const [ward, setWard] = React.useState("");
  const [building, setBuilding] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [floor, setFloor] = React.useState("");
  const [contactName, setContactName] = React.useState("");
  const [contactPhone, setContactPhone] = React.useState("");
  const [contactEmail, setContactEmail] = React.useState("");
  const [expectedShippingDate, setExpectedShippingDate] = React.useState(
    new Date()
  );
  const [pickUpSampleStartTime, setPickUpSampleStartTime] = React.useState(dayjs());
  const [pickUpSampleEndTime, setPickUpSampleEndTime] = React.useState(dayjs().add(3, "hour"));
  const [carrier, setCarrier] = React.useState("");
  const [city, setCity] = React.useState("");
  const [zipcode, setZipcode] = React.useState("");
  const [country, setCountry] = React.useState("");
  const scrollRef = React.useRef();
  const [fieldErrors, setFieldErrors] = React.useState({});
  const [patients, setPatients] = React.useState([
    {
      selectedPatient: "",
      analyses: [
        {
          selectedAnalysis: "",
          selectedVisits: [],
          totalSamples: "",
          analysisType: "",
        },
      ],
    },
  ]);
  
  const [timeError, setTimeError] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [shippingAddress, setShippingAddress] = React.useState("");

  React.useEffect(() => {
    setHospital(center?.hospital || "");
    setWard(center?.ward || "");
    setBuilding(center?.building || "");
    setStreet(center?.street || "");
    setCity(center?.city || "");
    setZipcode(center?.zipcode || "");
    setCountry(center?.country || "");
    setContactPhone(center?.contactPhone || "");
    setContactName(
      center?.contactName ? center.contactName.replace(/,\s*/, " ") : ""
    );
    setContactEmail(center?.contactEmail || "");
  }, [center]);

  React.useEffect(() => {
    if (centerId) {
      store.dispatch(getCenterData(centerId));
    }
  }, [centerId]);

  const handleSaveClick = () => {
    let isValid = true;
    let errorMessage = "";

    if (patients.length === 0) {
      isValid = false;
      errorMessage = "At least one patient is required.";
    } else {
      patients.forEach((section, patientIndex) => {
        if (!section.analyses || section.analyses.length === 0) {
          isValid = false;
          errorMessage = `Patient ID ${
            section.selectedPatient || "N/A"
          } must have at least one analysis.`;
        } else {
          section.analyses.forEach((analysis, analysisIndex) => {
            if (
              !analysis.selectedVisits ||
              analysis.selectedVisits.length === 0
            ) {
              isValid = false;
              errorMessage = `Analysis ${analysisIndex + 1} for patient ID ${
                section.selectedPatient || "N/A"
              } must have selected visits.`;
            }
            if (!analysis.totalSamples || analysis.totalSamples === "") {
              isValid = false;
              errorMessage = `Analysis ${analysisIndex + 1} for patient ID ${
                section.selectedPatient || "N/A"
              } must have the total number of samples specified.`;
            }
          });
        }
      });
    }
    if (!isValid) {
      setErrorMessage(errorMessage);
      return;
    }
    setShippingAddress(`${city} ${country}`);
    const shipmentData = {
      // TODO : Add a number to create sample shipment number (gradual by study?)
      shipmentNumber: "001",
      patients,
      expectedShippingDate,
      pickUpSampleStartTime: dayjs(pickUpSampleStartTime).format("HH:mm"),
      pickUpSampleEndTime: dayjs(pickUpSampleEndTime).format("HH:mm"),
      centerNumber,
      carrier,
      user,
      address: {
        street,
        city,
        zipcode,
        country,
        hospital,
        ward,
        building,
        floor,
        shippingAddress,
        contactName,
        contactPhone,
        contactEmail,
      },
    };
    const savedData = shipmentData;

    console.log("savedData", savedData);
    navigate("/sample-shipments/summary", {
      state: { savedData },
    });
  };

  const handleAddPatientClick = () => {
    setPatients([
      ...patients,
      {
        selectedPatient: "",
        analyses: [
          {
            selectedAnalysis: "",
            selectedVisits: [],
            totalSamples: "",
            analysisType: "",
          },
        ],
      },
    ]);
  };
  

  const handleAnalysisChange = (patientIndex, analysisIndex, event) => {
    const newPatientSections = [...patients];
    newPatientSections[patientIndex].analyses[analysisIndex].selectedAnalysis =
      event.target.value;
    newPatientSections[patientIndex].analyses[analysisIndex].selectedVisits =
      [];
    setPatients(newPatientSections);
  };

  const handleRemovePatientClick = (index) => {
    const newPatientSections = [...patients];
    newPatientSections.splice(index, 1);
    setPatients(newPatientSections);
  };

  const handlePatientChange = (index, event) => {
    const newPatientSections = [...patients];
    newPatientSections[index].selectedPatient = event.target.value;
    setPatients(newPatientSections);
  };

  const handleVisitChange = (patientIndex, analysisIndex, event) => {
    const {
      target: { value },
    } = event;
    const newPatientSections = [...patients];
    newPatientSections[patientIndex].analyses[analysisIndex].selectedVisits =
      typeof value === "string" ? value.split(",") : value;
    setPatients(newPatientSections);
  };

  const handleSampleChange = (patientIndex, analysisIndex, event) => {
    const value = Math.max(0, event.target.value); // min value 0
    const newPatientSections = [...patients];
    newPatientSections[patientIndex].analyses[analysisIndex].totalSamples =
      value;
    setPatients(newPatientSections);
  };

  const handleAddAnalysisClick = (patientIndex) => {
    const newPatientSections = [...patients];
    newPatientSections[patientIndex].analyses =
      newPatientSections[patientIndex].analyses || [];
    newPatientSections[patientIndex].analyses.push({
      selectedAnalysis: "",
      selectedVisits: [],
      totalSamples: "",
      analysisType: "",
    });
    setPatients(newPatientSections);
  };

  const handleAnalysisTypeChange = (patientIndex, analysisIndex, event) => {
    const newPatientSections = [...patients];
    newPatientSections[patientIndex].analyses[analysisIndex].analysisType =
      event.target.value;
    setPatients(newPatientSections);
  };

  const handleRemoveAnalysisClick = (patientIndex, analysisIndex) => {
    const newPatientSections = [...patients];
    newPatientSections[patientIndex].analyses.splice(analysisIndex, 1);
    setPatients(newPatientSections);
  };


  const handleStartTimeChange = (newValue) => {
    setPickUpSampleStartTime(newValue);
    if (newValue) {
      setPickUpSampleEndTime(dayjs(newValue).add(3, "hour"));
    }
    setTimeError("");
  };

  const handleEndTimeChange = (newValue) => {
    if (
      pickUpSampleStartTime &&
      newValue &&
      dayjs(newValue).isBefore(dayjs(pickUpSampleStartTime).add(1, "hour"))
    ) {
      setTimeError(t("End time must be at least 1 hour after start time"));
    } else {
      setPickUpSampleEndTime(newValue);
      setTimeError("");
    }
  };

  return (
    <Grid item xs={12} ref={scrollRef}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <TextField
              value={t("instructions").toUpperCase()}
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
              sx={{ mt: 1, ml: 2 }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="body1" align="center">
                {t("formCompletionNotice")}
              </Typography>
              <Typography variant="body1" align="center" sx={{ mb: 2 }}>
                <strong>{t("requestEffectiveNotice")}</strong>
              </Typography>
            </Box>
            <TextField
              value={t("samplesPickupAddress").toUpperCase()}
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
              sx={{ mt: 1, ml: 2 }}
            />
            <AddressForm
              centerNumber={centerNumber}
              hospital={hospital}
              setHospital={setHospital}
              ward={ward}
              setWard={setWard}
              building={building}
              setBuilding={setBuilding}
              street={street}
              setStreet={setStreet}
              floor={floor}
              setFloor={setFloor}
              zipcode={zipcode}
              setZipcode={setZipcode}
              phone={contactPhone}
              setPhone={setContactPhone}
              name={contactName}
              setName={setContactName}
              email={contactEmail}
              setEmail={setContactEmail}
              fieldErrors={fieldErrors}
              center={center}
            />
          </Grid>
        </Box>
        <div>
          <section>
            {patients.map((section, patientIndex) => (
              <div
                key={patientIndex}
                style={{
                  backgroundColor: "whitesmoke",
                  padding: "16px",
                  borderRadius: "8px",
                  marginTop: "16px",
                }}
              >
                <FormControl>
                  <TextField
                    label="Patient ID"
                    type="text"
                    value={section.selectedPatient}
                    onChange={(event) =>
                      handlePatientChange(patientIndex, event)
                    }
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    sx={{ backgroundColor: "white" }}
                  />
                </FormControl>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Analysis</TableCell>
                        <TableCell>Primary or Backup</TableCell>
                        <TableCell>Visits</TableCell>
                        <TableCell>Destination</TableCell>
                        <TableCell>Shipping Temperature</TableCell>
                        <TableCell>Total Number of Samples</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {section.analyses &&
                        section.analyses.map((analysis, analysisIndex) => (
                          <TableRow key={analysisIndex}>
                            <TableCell>
                              <FormControl fullWidth>
                                <InputLabel
                                  htmlFor={`analysisSelect-${patientIndex}-${analysisIndex}`}
                                >
                                  Select Analysis
                                </InputLabel>
                                <Select
                                  id={`analysisSelect-${patientIndex}-${analysisIndex}`}
                                  label="Select Analysis"
                                  value={analysis.selectedAnalysis || ""}
                                  onChange={(event) =>
                                    handleAnalysisChange(
                                      patientIndex,
                                      analysisIndex,
                                      event
                                    )
                                  }
                                  sx={{ minWidth: 200 }}
                                >
                                  {analyses.map((analysis) => (
                                    <MenuItem
                                      key={analysis.id}
                                      value={analysis.id}
                                    >
                                      {analysis.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {analysis.selectedAnalysis && (
                                <p>
                                  {
                                    analyses.find(
                                      (a) => a.id === analysis.selectedAnalysis
                                    ).description
                                  }
                                </p>
                              )}
                            </TableCell>
                            <TableCell>
                              {analysis.selectedAnalysis && (
                                <FormControl component="fieldset" fullWidth>
                                  <RadioGroup
                                    row
                                    aria-label="analysisType"
                                    name={`analysisType-${patientIndex}-${analysisIndex}`}
                                    value={analysis.analysisType || ""}
                                    onChange={(event) =>
                                      handleAnalysisTypeChange(
                                        patientIndex,
                                        analysisIndex,
                                        event
                                      )
                                    }
                                  >
                                    <FormControlLabel
                                      value="Primary"
                                      control={<Radio />}
                                      label="Primary"
                                    />
                                    <FormControlLabel
                                      value="Backup"
                                      control={<Radio />}
                                      label="Backup"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              )}
                            </TableCell>
                            <TableCell>
                              {analysis.selectedAnalysis && (
                                <FormControl fullWidth>
                                  <InputLabel
                                    htmlFor={`visitSelect-${patientIndex}-${analysisIndex}`}
                                  >
                                    Select Visits
                                  </InputLabel>
                                  <Select
                                    id={`visitSelect-${patientIndex}-${analysisIndex}`}
                                    multiple
                                    label="Select Visits"
                                    value={analysis.selectedVisits || []}
                                    onChange={(event) =>
                                      handleVisitChange(
                                        patientIndex,
                                        analysisIndex,
                                        event
                                      )
                                    }
                                    renderValue={(selected) =>
                                      selected.join(", ")
                                    }
                                    sx={{ minWidth: 200 }}
                                  >
                                    {analyses
                                      .find(
                                        (a) =>
                                          a.id === analysis.selectedAnalysis
                                      )
                                      .visitIds.map((visit) => (
                                        <MenuItem key={visit} value={visit}>
                                          <Checkbox
                                            checked={
                                              analysis.selectedVisits.indexOf(
                                                visit
                                              ) > -1
                                            }
                                          />
                                          <ListItemText primary={visit} />
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              )}
                            </TableCell>
                            <TableCell>
                              {analysis.selectedAnalysis && (
                                <p>
                                  {
                                    analyses.find(
                                      (a) => a.id === analysis.selectedAnalysis
                                    ).destination
                                  }
                                </p>
                              )}
                            </TableCell>
                            <TableCell>
                              {analysis.selectedAnalysis && (
                                <p>
                                  {
                                    analyses.find(
                                      (a) => a.id === analysis.selectedAnalysis
                                    ).shippingTemperature
                                  }
                                </p>
                              )}
                            </TableCell>
                            <TableCell>
                              {analysis.selectedAnalysis && (
                                <TextField
                                  label="Total Number"
                                  type="number"
                                  value={analysis.totalSamples || ""}
                                  onChange={(event) =>
                                    handleSampleChange(
                                      patientIndex,
                                      analysisIndex,
                                      event
                                    )
                                  }
                                  fullWidth
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                  handleRemoveAnalysisClick(
                                    patientIndex,
                                    analysisIndex
                                  )
                                }
                              >
                                X
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAddAnalysisClick(patientIndex)}
                          >
                            Add an analysis +
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: "16px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRemovePatientClick(patientIndex)}
                  >
                    Remove Patient
                  </Button>
                </div>
              </div>
            ))}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddPatientClick}
                style={{ padding: "16px 38px" }}
              >
                Add a patient +
              </Button>
            </div>
          </section>

          <TextField
            value={t("transportInformation").toUpperCase()}
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: { textAlign: "center" },
            }}
            fullWidth
            sx={{ mt: 1 }}
          />

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ marginTop: "12px" }}
          >
            <CustomDatePicker
              label={t("expectedShippingDate")}
              name="expectedShippingDate"
              value={expectedShippingDate}
              onChange={setExpectedShippingDate}
              minDate={new Date()}
            />

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>{t("carrier")}</InputLabel>
                <Select
                  label={t("carrier")}
                  value={carrier}
                  onChange={(e) => setCarrier(e.target.value)}
                  name="carrier"
                >
                  <MenuItem value="GoPak">GoPak</MenuItem>
                  <MenuItem value="QuickSTAT">QuickSTAT</MenuItem>
                  <MenuItem value="Transcourrier">Transcourrier</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
                sx={{ p: 2 }}
              >
                <Grid item>
                  <p>Pick up sample time: between</p>
                </Grid>
                <Grid item>
                  <TimePicker
                    label={t("startTime")}
                    value={pickUpSampleStartTime}
                    ampm={false}
                    onChange={handleStartTimeChange}
                  >
                    <TextField
                      fullWidth
                      error={!!timeError}
                      helperText={timeError}
                    />
                  </TimePicker>
                </Grid>
                <Grid item>
                  <p>and</p>
                </Grid>
                <Grid item>
                  <TimePicker
                    label={t("endTime")}
                    value={pickUpSampleEndTime}
                    ampm={false}
                    onChange={handleEndTimeChange}
                    minTime={
                      pickUpSampleStartTime
                        ? pickUpSampleStartTime.add(1, "hour")
                        : null
                    }
                  >
                    <TextField
                      fullWidth
                      error={!!timeError}
                      helperText={timeError}
                    />
                  </TimePicker>
                </Grid>
              </Grid>
            </LocalizationProvider>
            <Grid container justifyContent="center" alignItems="center">
              {errorMessage && (
                <Typography color="error" variant="body1">
                  {errorMessage}
                </Typography>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveClick}
                style={{ padding: "16px 38px" }}
              >
                Save
              </Button>
            </Grid>
          </Grid>

          <UserInfo
            title={t("personRequestingShipment").toUpperCase()}
            user={user}
            date={new Date().toLocaleDateString()}
          />
        </div>
      </Paper>
    </Grid>
  );
};
