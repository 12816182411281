import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import LogoutIcon from "@mui/icons-material/Logout";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import UsersIcon from "@mui/icons-material/People";
import ProductionsIcon from "@mui/icons-material/Biotech";
import StudiesIcon from "@mui/icons-material/QueryStats";
import KitsIcon from "@mui/icons-material/Science";
import CentersIcon from "@mui/icons-material/Store";
import SponsorsIcon from "@mui/icons-material/Apartment";
import SampleIcon from "@mui/icons-material/Send";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVial } from "@fortawesome/free-solid-svg-icons";
import { faFlaskVial } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import { Copyright } from "../Copyright";
import { store } from "../../store";
import { logoutAction } from "../../redux/actions/root";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Drawer } from "../ui/Drawer";
import AppBarComponent from "../ui/AppBar";
import renderTitle from "./Title";
import { getCenterData } from "../../redux/actions/center";
import HelpIcon from "@mui/icons-material/Help";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../LanguageSwitcher";

export const Protected = ({ children }) => {
  const { t } = useTranslation();
  const [, setStudySelected] = React.useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const adminUser = user.role === "admin";
  const cdlUser = user.organization === "CDL";
  const employeeUser = user.role === "employee" && cdlUser;
  const sponsorUser = user.organization === "Sponsor";
  const centerUser = user.organization === "Center";
  const monitorUser = user.role === "monitor";
  const center = useSelector((state) => state.center.center);
  const [open, setOpen] = React.useState(true);
  const location = useLocation();
  const studyCode = centerUser
    ? center?.Study?.sponsorStudyCode
    : location.state?.studyCode;
  const studyId = location.state?.studyId;
  const centerNumber = centerUser
    ? center?.centerNumber
    : location.state?.centerNumber;
  const centerId = centerUser ? user.centerId : location.state?.centerId;

  const showTitle = renderTitle(
    location,
    studyCode,
    centerNumber,
    centerUser,
    centerId,
    t
  );

  React.useEffect(() => {
    if (centerUser) {
      store.dispatch(getCenterData(user.centerId));
    }
  }, [centerUser, user.centerId]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  if (!user) <CircularProgress />;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarComponent
        open={open}
        toggleDrawer={toggleDrawer}
        showTitle={showTitle}
        user={user}
      />
      {!user ? (
        <CircularProgress></CircularProgress>
      ) : (
        <Drawer variant="permanent" open={open}>
          <div>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <List>
              {/* LIST OF STUDIES */}
              {(cdlUser || sponsorUser) && (
                <ListItemButton
                  selected={location.pathname === "/studies"}
                  onClick={() => {
                    navigate("/studies");
                    setStudySelected(!!studyCode);
                  }}
                >
                  <ListItemIcon>
                    <StudiesIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("studies")} />
                </ListItemButton>
              )}
              {studyCode && !centerUser && (
                <>
                  <Divider />
                  <ListItemButton
                    onClick={() => {
                      navigate(`/centers`, { state: { studyId, studyCode } });
                    }}
                    sx={{ paddingLeft: 2 }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ListItemIcon>
                        <StudiesIcon />
                      </ListItemIcon>
                      <Typography variant="h6"> {studyCode} </Typography>
                    </Box>
                    <Divider />
                  </ListItemButton>
                </>
              )}

              {studyCode && (cdlUser || sponsorUser) && !centerNumber && (
                <ListItemButton
                  selected={location.pathname === "/centers"}
                  onClick={() => {
                    navigate(`/centers`, { state: { studyId, studyCode } });
                  }}
                  sx={{ paddingLeft: 4 }}
                >
                  <ListItemIcon>
                    <CentersIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("centers")} />
                </ListItemButton>
              )}

              {/*  CENTER SELECTED */}
              {centerNumber && !centerUser && (
                <>
                  <Divider />
                  <ListItemButton>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ListItemIcon>
                        <CentersIcon />
                      </ListItemIcon>
                      <Typography variant="h6">
                        {t("center")} {centerNumber}
                      </Typography>
                    </Box>
                  </ListItemButton>
                </>
              )}
              <Divider />
              {((studyCode && !monitorUser) ||
                centerUser ||
                (monitorUser && centerId)) && (
                <>
                  <ListItemButton
                    selected={location.pathname === "/materials"}
                    onClick={() => {
                      navigate("/materials", {
                        state: { studyId, studyCode, centerNumber, centerId },
                      });
                    }}
                    sx={{ paddingLeft: 4 }}
                  >
                    <ListItemIcon>
                      <KitsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("materialList")} />
                  </ListItemButton>
                  <Divider />
                </>
              )}

              {((studyCode && !monitorUser) ||
                centerUser ||
                (monitorUser && centerId)) && (
                <>
                  <ListItemButton
                    selected={location.pathname === "/productions"}
                    onClick={() => {
                      navigate("/productions", {
                        state: { studyId, studyCode, centerNumber, centerId },
                      });
                    }}
                    sx={{ paddingLeft: 4 }}
                  >
                    <ListItemIcon>
                      <ProductionsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        centerUser || location.state?.centerId
                          ? t("inventory")
                          : t("productions")
                      }
                    />
                  </ListItemButton>

                  <Divider />

                  {!employeeUser && (
                    <ListItemButton
                      selected={
                        location.pathname === "/orders/" ||
                        location.pathname === "/order"
                      }
                      onClick={() => {
                        navigate("/orders/", {
                          state: { centerNumber, studyCode, studyId, centerId },
                        });
                      }}
                      sx={{ paddingLeft: 4 }}
                    >
                      <ListItemIcon>
                        <ShoppingCartIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("orders")} />
                    </ListItemButton>
                  )}
                  {adminUser && !centerNumber && (
                    <>
                      <Divider />
                      <ListItemButton
                        selected={location.pathname === "/study-info"}
                        onClick={() => {
                          navigate("/study-info", {
                            state: { studyCode, studyId },
                          });
                        }}
                        sx={{ paddingLeft: 4 }}
                      >
                        <ListItemIcon>
                          <StudiesIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("studyInfo")} />
                      </ListItemButton>
                    </>
                  )}
                  {!centerUser && (
                    <>
                      <Divider />
                      <ListItemButton
                        selected={location.pathname === "/sample-shipments"}
                        onClick={() => {
                          navigate("/sample-shipments", {
                            state: {
                              centerNumber,
                              studyCode,
                              studyId,
                              centerId,
                            },
                          });
                        }}
                        sx={{ paddingLeft: 4 }}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faVial} />
                        </ListItemIcon>
                        <ListItemText primary={t("samples")} />
                      </ListItemButton>
                      <Divider />
                      <ListItemButton
                        selected={location.pathname === "/analysis"}
                        onClick={() => {
                          navigate("/analysis", {
                            state: {
                              centerNumber,
                              studyCode,
                              studyId,
                              centerId,
                            },
                          });
                        }}
                        sx={{ paddingLeft: 6 }}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faFlaskVial} />
                        </ListItemIcon>
                        <ListItemText primary={t("analysisList")} />
                      </ListItemButton>
                      <Divider />
                      <ListItemButton
                        selected={location.pathname === "/sample-shipments"}
                        onClick={() => {
                          navigate("/sample-shipments", {
                            state: {
                              centerNumber,
                              studyCode,
                              studyId,
                              centerId,
                            },
                          });
                        }}
                        sx={{ paddingLeft: 6 }}
                      >
                        <ListItemIcon>
                          <SampleIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("shipments")} />
                      </ListItemButton>
                    </>
                  )}

                  {adminUser && centerNumber && (
                    <>
                      <Divider />
                      <ListItemButton
                        selected={location.pathname === "/center-info"}
                        onClick={() => {
                          navigate(`/center-info`, {
                            state: {
                              centerNumber,
                              studyCode,
                              studyId,
                              centerId,
                            },
                          });
                        }}
                        sx={{ paddingLeft: 4 }}
                      >
                        <ListItemIcon>
                          <CentersIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("centerInfo")} />
                      </ListItemButton>
                    </>
                  )}
                  <Divider />
                </>
              )}

              {adminUser && (
                <>
                  <ListItemButton
                    selected={location.pathname === "/sponsors"}
                    onClick={() => {
                      navigate("/sponsors");
                      setStudySelected(false);
                    }}
                  >
                    <ListItemIcon>
                      <SponsorsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("sponsors")} />
                  </ListItemButton>
                  <Divider />
                  <ListItemButton
                    selected={location.pathname === "/users"}
                    onClick={() => {
                      navigate("/users");
                      setStudySelected(false);
                    }}
                  >
                    <ListItemIcon>
                      <UsersIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("users")} />
                  </ListItemButton>
                  <Divider />
                </>
              )}
              {cdlUser && !employeeUser && (
                <ListItemButton
                  selected={location.pathname === "/orders"}
                  onClick={() => {
                    navigate("/orders");
                  }}
                >
                  <ListItemIcon>
                    <ShoppingCartIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("orders")} />
                </ListItemButton>
              )}
              <Divider />
              <ListItemButton
                onClick={() => {
                  navigate("/");
                  store.dispatch(logoutAction());
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={t("logout")} />
              </ListItemButton>
            </List>
          </div>
          <div>
            <LanguageSwitcher />
            <Divider />
            <ListItemButton
              onClick={() => {
                window.open(
                  "https://ablsa.atlassian.net/servicedesk/customer/portal/23",
                  "_blank"
                );
              }}
            >
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <Tooltip title={t("sendTicketToCustomerSupport")}>
                <ListItemText primary={t("support")} />
              </Tooltip>
            </ListItemButton>
          </div>
        </Drawer>
      )}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 2, mb: 4 }}>
          <Grid container>
            <Outlet />
          </Grid>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
};
