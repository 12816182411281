import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../utils/CustomDatePicker";

const EditFieldDialog = ({ open, onClose, field, label, value, onSave }) => {
  const { t } = useTranslation();
  const [newValue, setNewValue] = React.useState(value);

  React.useEffect(() => {
    setNewValue(value);
  }, [value]);

  const handleSave = () => {
    onSave(field, newValue);
    onClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Update {label}</DialogTitle>
        <DialogContent sx={{ paddingTop: "16px" }}>
          {field.includes("Date") ? (
            <CustomDatePicker
              value={newValue}
              onChange={(newDate) => setNewValue(newDate)}
              fullWidth
              InputProps={{
                startAdornment: null,
                endAdornment: null,
                style: { padding: 0 },
              }}
              InputLabelProps={{
                shrink: false,
              }}
            />
          ) : field === "carrier" ? (
            <Select
              label={label}
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
              fullWidth
              margin="dense"
            >
              <MenuItem value="Area Time">Area Time</MenuItem>
              <MenuItem value="GoPak">GoPak</MenuItem>
              <MenuItem value="Marken">Marken</MenuItem>
              <MenuItem value="QuickSTAT">QuickSTAT</MenuItem>
              <MenuItem value="Transcourrier">Transcourrier</MenuItem>
            </Select>
          ) : (
            <TextField
              label={label}
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
              fullWidth
              margin="dense"
              InputLabelProps={{ style: { whiteSpace: "nowrap" } }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("cancel")}</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default EditFieldDialog;
