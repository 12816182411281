import { instance } from "../axios";

export const createOrder = async (orderData) => {
  try {
    const response = await instance({
      method: "POST",
      url: "/order",
      data: orderData,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw new Error(error.response.data.error || "Validation error, a field is missing or invalid");
    } else {
      throw new Error(error.message || "An error occurred while creating the order");
    }
  }
};

export const createSupply = async (supplyData) => {
  const response = await instance({
    method: "POST",
    url: "/supply",
    data: supplyData,
  });
  return response.data;
};

export const fetchOrder = async (orderId) => {
  const response = await instance({
    method: "GET",
    url: `/order/${orderId}`,
  });
  return response.data;
};

export const updateOrderStatus = async (orderId, newStatus, confirmedById = null, confirmationDate = null, comment = null) => {
  const data = {
    status: newStatus,
  };

  if (confirmedById) {
    data.confirmedById = confirmedById;
  }

  if (confirmationDate) {
    data.confirmationDate = confirmationDate;
  }

  if (comment) {
    data.comment = comment;
  }

  await instance({
    method: "PUT",
    url: `/order/${orderId}`,
    data: data,
  });
};

export const updateOrderComment = async (orderId, comment) => {
  await instance({
    method: "PUT",
    url: `/order/${orderId}`,
    data: { comment },
  });
}