import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SelectOptionsDialog = ({
  open,
  handleClose,
  params,
  options,
  selectedProductions,
  setProductionsSent,
  productionsSent,
  neededQuantity,
  totalSent, labelFormat
}) => {
  const { t } = useTranslation();
  const getLabel = (option) => {
    if (labelFormat !== 'default') {
      return `(${option.batchNumber}) ${option.reference} ${option.comment}`;
    }
    return `(${option.batchNumber}) ${option.reference}`;
  };


  return (
    <Dialog
      open={open[params.row.Material.id] || false}
      onClose={() => handleClose(params.row.Material.id)}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: { padding: "20px", maxHeight: "70vh" },
      }}
    >
      <DialogTitle id="form-dialog-title">
        {t("selectProductionsToSend")}
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          {options
            .sort((a, b) => a.reference - b.reference) // sort options in ascending order
            .map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={selectedProductions.some(
                      (selected) => selected.id === option.id
                    )}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setProductionsSent({
                          ...productionsSent,
                          [params.row.Material.id]: [
                            ...(productionsSent[params.row.Material.id] || []),
                            option,
                          ],
                        });

                        // Check if totalSent equals neededQuantity after the option is added
                        if (totalSent + 1 >= neededQuantity) {
                          handleClose(params.row.Material.id);
                        }
                      } else {
                        setProductionsSent({
                          ...productionsSent,
                          [params.row.Material.id]: (
                            productionsSent[params.row.Material.id] || []
                          ).filter(
                            (selected) => selected.id !== option.id
                          ),
                        });
                      }
                    }}
                    disabled={totalSent >= neededQuantity}
                  />
                }
                label={getLabel(option)}
              />
            ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(params.row.Material.id)}
          color="primary"
          variant="contained"
        >
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectOptionsDialog;