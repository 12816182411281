import React from "react";
import {
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TextField,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../ui/utils/CustomDatePicker";

const analyses = [
  {
    id: 1,
    name: "PK for Irinotecan",
    visitIds: ["C1D1", "C1D3", "C1D15", "Additional C1D14"],
    shippingTemperature: "Dry Ice",
    description: "1 tube /visit",
    destination: "TPL",
    primary: true,
  },
  {
    id: 2,
    name: "PK for Oxaliplatin",
    visitIds: ["C1D1", "C1D3", "C1D15", "Additional C1D14"],
    shippingTemperature: "4°C",
    description: "2 tubes per timepoint",
    destination: "CDL",
    primary: false,
  },
  {
    id: 3,
    name: "Plasma",
    visitIds: ["C1D9", "C2D1", "C4D1"],
    shippingTemperature: "Room Temperature",
    description: "1 tube /visit",
    destination: "TPL",
    primary: true,
  },
  {
    id: 4,
    name: "PBMC",
    visitIds: ["C1D9", "C2D1", "C4D1"],
    shippingTemperature: "Dry Ice",
    description: "2 tubes per timepoint",
    destination: "CDL",
    primary: true,
  },
  {
    id: 5,
    name: "MRD",
    visitIds: ["C1D9", "C2D1", "C4D1"],
    shippingTemperature: "4°C",
    description: "1 tube /visit",
    destination: "TPL",
    primary: true,
  },
  {
    id: 6,
    name: "BBMC",
    visitIds: ["C1D9", "C2D1", "C4D1"],
    shippingTemperature: "Room Temperature",
    description: "2 tubes per timepoint",
    destination: "CDL",
    primary: true,
  },
];

const ScreenSampleConfirmationForm = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [shipmentData, setShipmentData] = React.useState(
    location.state?.shipmentData || {}
  );
  const [status, setStatus] = React.useState("Waiting for pickup by carrier");
  const [biobankingDate, setBiobankingDate] = React.useState(null);
  const [initials, setInitials] = React.useState("");
  const [primarySamplesStorageLocation, setPrimarySamplesStorageLocation] =
    React.useState("");
  const [backupSamplesStorageLocation, setBackupSamplesStorageLocation] =
    React.useState("");
  const [comments, setComments] = React.useState("");

  const calculateShippingTemperatureTotals = () => {
    const totals = {
      "Dry Ice": 0,
      "4°C": 0,
      "Room Temperature": 0,
    };

    shipmentData.savedData?.patients?.forEach((patient) => {
      patient.analyses.forEach((analysis) => {
        const shippingTemp = analyses.find(
          (a) => a.id === analysis.selectedAnalysis
        )?.shippingTemperature;

        if (shippingTemp && totals[shippingTemp] !== undefined) {
          totals[shippingTemp] += analysis.totalSamples || 0;
        }
      });
    });

    return Object.entries(totals).map(([key, value]) => ({
      temperature: key,
      total: value,
    }));
  };

  const shippingTemperatureSummary = calculateShippingTemperatureTotals();

  const handleConfirmClick = () => {
    setStatus("Received");
  };

  const handleBiobankingDateChange = (date) => {
    setBiobankingDate(date);
  };

  const handleInitialsChange = (event) => {
    setInitials(event.target.value);
  };

  const handlePrimarySamplesStorageLocationChange = (event) => {
    setPrimarySamplesStorageLocation(event.target.value);
  };

  const handleBackupSamplesStorageLocationChange = (event) => {
    setBackupSamplesStorageLocation(event.target.value);
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const handleTotalReceivedChange = (event, patientIndex, analysisIndex) => {
    const newTotalReceived = event.target.value;
    const updatedPatients = [...shipmentData.savedData.patients];
    updatedPatients[patientIndex].analyses[analysisIndex].totalReceived =
      newTotalReceived;
    setShipmentData({
      ...shipmentData,
      savedData: {
        ...shipmentData.savedData,
        patients: updatedPatients,
      },
    });
  };

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Box sx={{ p: 2 }}>
          <TextField
            value={t("shipmentConfirmationForm").toUpperCase()}
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: { textAlign: "center" },
            }}
            fullWidth
          />
          <Grid container spacing={2} alignItems="center" sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={6}>
              <TextField
                label={t("shipmentNumber")}
                value={shipmentData.savedData.shipmentNumber}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("status")}
                value={status}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("dateOfShipmentConfirmation")}
                value={new Date().toLocaleDateString()}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("centerId")}
                value={shipmentData.savedData.centerNumber || "N/A"}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t("deliveryPlace")}
                value={shipmentData.laboratory || "VelaDx USA"}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("pickupExpectedDate")}
                value={`${new Date(
                  shipmentData.dateOfShipment
                ).toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })} between ${shipmentData.startTimeOfShipment} and ${
                  shipmentData.endTimeOfShipment
                }`}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("shippingTemperature")}</TableCell>
                      <TableCell>{t("totalSamples")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shippingTemperatureSummary.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.temperature}</TableCell>
                        <TableCell>{item.total}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("carrier")}
                value={shipmentData.savedData.carrier || "N/A"}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label={t("airwaybill")}
                value={shipmentData.airwaybill}
                variant="outlined"
                fullWidth
              />
                           <Grid item xs={12} container justifyContent={"space-around"}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: "16px 40px", margin: "8px 0px" }}
                >
                  {t("viewAirwaybill")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: "16px 40px", margin: "8px 0px" }}
                >
                  {t("downloadConfirmationForm")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {shipmentData.savedData.patients &&
            shipmentData.savedData.patients.map((patient, patientIndex) => (
              <section
                key={patientIndex}
                style={{
                  marginBottom: "16px",
                  backgroundColor: "#f5f5f5",
                  padding: "10px",
                }}
              >
                <h3>Patient ID {patient.selectedPatient}</h3>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Analysis</TableCell>
                        <TableCell>Primary or Backup</TableCell>
                        <TableCell>Visits</TableCell>
                        <TableCell>Destination</TableCell>
                        <TableCell>Shipping Temperature</TableCell>
                        <TableCell>Total Number of Samples Sent</TableCell>
                        <TableCell>Total Received</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {patient.analyses &&
                        patient.analyses.map((analysis, analysisIndex) => (
                          <TableRow key={analysisIndex}>
                            <TableCell>
                              {
                                analyses.find(
                                  (a) => a.id === analysis.selectedAnalysis
                                )?.name
                              }
                            </TableCell>
                            <TableCell>{analysis.analysisType}</TableCell>
                            <TableCell>
                              {analysis.selectedVisits.join(", ")}
                            </TableCell>
                            <TableCell>
                              {
                                analyses.find(
                                  (a) => a.id === analysis.selectedAnalysis
                                )?.destination
                              }
                            </TableCell>
                            <TableCell>
                              {
                                analyses.find(
                                  (a) => a.id === analysis.selectedAnalysis
                                )?.shippingTemperature
                              }
                            </TableCell>
                            <TableCell>{analysis.totalSamples}</TableCell>
                            <TableCell>
                              {status === "received" ? (
                                analysis.totalReceived
                              ) : (
                                <TextField
                                  value={analysis.totalReceived}
                                  type="number"
                                  onChange={(e) =>
                                    handleTotalReceivedChange(
                                      e,
                                      patientIndex,
                                      analysisIndex
                                    )
                                  }
                                  fullWidth
                                  inputProps={{ min: 0 }}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </section>
            ))}
          <TextField
            value={t("biobankingOfSamples").toUpperCase()}
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: { textAlign: "center" },
            }}
            fullWidth
          />

          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{t("biobankingDate")}</TableCell>
                  <TableCell>
                    <CustomDatePicker
                      label={t("biobankingDate")}
                      name={t("biobankingDate")}
                      value={biobankingDate}
                      onChange={handleBiobankingDateChange}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("initials")}</TableCell>
                  <TableCell>
                    <TextField
                      value={initials}
                      onChange={handleInitialsChange}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("primarySamplesStorageLocation")}</TableCell>
                  <TableCell>
                    <TextField
                      value={primarySamplesStorageLocation}
                      onChange={handlePrimarySamplesStorageLocationChange}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("backupSamplesStorageLocation")}</TableCell>
                  <TableCell>
                    <TextField
                      value={backupSamplesStorageLocation}
                      onChange={handleBackupSamplesStorageLocationChange}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("comments")}</TableCell>
                  <TableCell>
                    <TextField
                      value={comments}
                      onChange={handleCommentsChange}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container item xs={12} justifyContent={"right"}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmClick}
              style={{ padding: "16px 88px", margin: "16px" }}
            >
              {t("confirmReception")}
            </Button>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default ScreenSampleConfirmationForm;
