import { instance } from "../axios";

export const createShipment = async (orderId, newShipment, details, user) => {
  const data = {
    orderId: orderId,
    preparationDate: newShipment?.preparationDate,
    sendingDate: newShipment?.sendingDate,
    carrierOrderDate: newShipment?.carrierOrderDate,
    carrier: newShipment?.carrier,
    packingSlip: newShipment?.packingSlip,
    packageDetails: details,
    shippedQuantity: newShipment?.shippedQuantity,
    status:
      newShipment?.status === "draft"
        ? "draft"
        : new Date(newShipment?.sendingDate) < new Date()
        ? "shipped"
        : "programmed",
    shippedById: user.id,
    comment: newShipment?.comment,
  };

  if (newShipment?.status === "draft") {
    data.draftData = JSON.stringify(newShipment.draftData);
    data.draftOrder = newShipment.draftOrder;
  }

  const response = await instance({
    method: "POST",
    url: "/shipment",
    data: data,
  });

  return response.data;
};

export const getShipmentById = async (shipmentId) => {
  const response = await instance.get(`/shipment/${shipmentId}`);
  return response.data;
};

export const getDraftShipmentByOrderId = async (orderId) => {
  try {
    const response = await instance.get(`/shipment/draft/${orderId}`);
    if (response.status === 200 && Array.isArray(response.data) && response.data.length === 0) {
      return null;
    }
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

export const updateShipmentStatus = async (shipment, status, comment) => {
  await instance.put(`/shipment/${shipment.id}`, {
    ...shipment,
    status: status,
    comment: comment,
  });
};

export const updateShipmentDelivery = async (
  shipmentId,
  status,
  deliveryDate
) => {
  await instance.put(`/shipment/${shipmentId}`, {
    status: status,
    deliveryDate: deliveryDate,
  });
};

export const updateShipmentComment = async (shipmentId, comment) => {
  await instance.put(`/shipment/${shipmentId}`, {
    comment: comment,
  });
}

export const updateShipmentReceived = async (shipmentId, userId, confirmedDate) => {
  await instance.put(`/shipment/${shipmentId}`, {
    confirmedDeliveryDate: confirmedDate,
    receivedById: userId,
  });
};

export const updateShipment = async (shipmentId, field, newValue) => {
  const data = { [field]: field === 'draftData' ? JSON.stringify(newValue) : newValue };
  await instance.put(`/shipment/${shipmentId}`, data);
};

export const deleteShipment = async (shipmentId) => {
  await instance.delete(`/shipment/${shipmentId}`);
}
