import * as React from "react";
import { Grid, Paper, Box, TextField } from "@mui/material";
import { LinearProgress, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReadOnlyTextField from "../../ui/ReadOnlyTextField";
import { store } from "../../../store";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import { getOrder } from "../../../redux/actions/order";
import OrderTable from "../../ui/OrderTable";
import { getProductionsByMaterial } from "../../../redux/actions/production";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { fetchProductionsByOrderId } from "../../../services/productionService";
import CustomDatePicker from "../../ui/utils/CustomDatePicker";
import HandlePackageDetails from "../../ui/HandlePackageDetails";
import { useTranslation } from "react-i18next";
import SelectOptionsDialog from "../../ui/dialogs/SelectOptionsDialog";
import AddressContactInfo from "../../ui/AddressContactInfo";
import {
  createShipment,
  getDraftShipmentByOrderId,
  updateShipment,
} from "../../../services/shipmentService";
import { showErrorAlert, showSuccessAlert } from "../../ui/utils/AlertUtils";

export const ScreenCreateShippingForm = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const users = useSelector((state) => state.user.users);
  const user = useSelector((state) => state.user.user);
  const isAdmin = user.role === "admin";
  const cdlUsers = users.filter(
    (user) =>
      user.organization === "CDL" &&
      user.role !== "employee" &&
      user.status === "active"
  );
  const orderId = location.state?.orderId;
  const centerId = location.state?.centerId;
  const studyId = location.state?.studyId;
  const order = useSelector((state) => state.order.order);
  const initialShipment = location.state?.newShipment || {};
  const centerNumber = location.state?.centerNumber;
  const studyCode = location.state?.studyCode;
  const today = new Date().toISOString().split("T")[0];
  const [selectedUser, setSelectedUser] = React.useState(
    initialShipment.shippedBy || ""
  );
  const [productions, setProductions] = React.useState({});
  const [productionsSent, setProductionsSent] = React.useState(
    initialShipment.productionsSent || {}
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [carrierOrderDate, setCarrierOrderDate] = React.useState(
    initialShipment.carrierOrderDate || today
  );
  const [sendingDate, setSendingDate] = React.useState(
    initialShipment.sendingDate || today
  );
  const [preparationDate, setPreparationDate] = React.useState(
    initialShipment.preparationDate || today
  );
  const [numPackages, setNumPackages] = React.useState(
    Number(initialShipment.numPackages) || ""
  );
  const [packages, setPackages] = React.useState(
    initialShipment.packages || []
  );
  const [packingSlip, setPackingSlip] = React.useState(
    initialShipment.packingSlip || ""
  );
  const [carrier, setCarrier] = React.useState(initialShipment.carrier || "");
  const [comment, setComment] = React.useState(initialShipment.comment || "");
  const [shippedQuantity, setShippedQuantity] = React.useState(
    initialShipment.shippedQuantity || 0
  );
  const [totalSentByMaterial, setTotalSentByMaterial] = React.useState({});
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const fullAddress = AddressContactInfo({ order });

  // for the select Shipped By
  const handleChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleClickOpen = (id) => {
    setOpen((prevOpen) => ({ ...prevOpen, [id]: true }));
  };

  const handleClose = (id) => {
    setOpen((prevOpen) => ({ ...prevOpen, [id]: false }));
  };

  // function to calculate the total quantity of productions already sent for this shipment
  React.useEffect(() => {
    const getTotalSentByMaterial = async () => {
      const productions = await fetchProductionsByOrderId(orderId);

      const totalSent = {};

      for (const production of productions) {
        if (!totalSent[production.materialId]) {
          totalSent[production.materialId] = 1;
        } else {
          totalSent[production.materialId]++;
        }
      }

      setTotalSentByMaterial(totalSent);
    };

    getTotalSentByMaterial();
  }, [orderId]);

  // function to calculate the total quantity of productions sent for this shipment
  React.useEffect(() => {
    let total = 0;
    for (let key in productionsSent) {
      total += productionsSent[key].length;
    }
    setShippedQuantity(total);
  }, [productionsSent]);


  const handleNumPackagesChange = (event) => {
    setNumPackages(event.target.value);
  };

  const validateShipment = (shipment) => {
    const requiredFields = [
      "carrier",
      "packingSlip",
      "numPackages",
      "packages",
    ];

    // Check if productionsSent is an object and contains at least one object in one of its arrays
    if (
      typeof shipment.productionsSent !== "object" ||
      !Object.values(shipment.productionsSent).some((array) => array.length > 0)
    ) {
      return false;
    }

    for (let field of requiredFields) {
      if (
        !shipment[field] ||
        (Array.isArray(shipment[field]) && shipment[field].length === 0)
      ) {
        return false;
      }
    }

    return true;
  };

  // action for sending shipment button
  const handleNewShipment = () => {
    const newShipment = {
      productionsSent: productionsSent,
      sendingDate: sendingDate,
      packingSlip: packingSlip,
      carrier: carrier,
      carrierOrderDate: carrierOrderDate,
      preparationDate: preparationDate,
      numPackages: numPackages,
      packages: packages,
      shippedQuantity: shippedQuantity,
      comment: comment,
      orderId: orderId,
      shippedBy: selectedUser !== "" ? selectedUser : null,
    };

    if (!validateShipment(newShipment)) {
      setShowErrorMessage(true);
      return;
    }
    setShowErrorMessage(false);
    navigate("/shipping/confirm", {
      state: {
        newShipment,
        orderId,
        studyCode,
        centerNumber,
        centerId,
        studyId,
      },
    });
  };

  const materialsTypeMaterial =
    order?.Supplies?.filter((supply) => supply.Material.type === "material") ||
    [];
  const materialsTypeSupply =
    order?.Supplies?.filter((supply) => supply.Material.type === "supply") ||
    [];
  const materialsTypeReagent =
    order?.Supplies?.filter((supply) => supply.Material.type === "reagent") ||
    [];
  const materialsTypeDocuments =
    order?.Supplies?.filter((supply) => supply.Material.type === "document") ||
    [];

  const CustomDataGrid = ({ rows, headerName }) => {
    const columns = [
      {
        field: "materialName",
        headerName: t("materialName"),
        flex: 0.2,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row.Material.name,
      },
      {
        field: "quantity",
        headerName: t("neededQuantity"),
        flex: 0.08,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) => {
          const totalNeeded = params.row.quantity;
          const totalSent = totalSentByMaterial[params.row.Material.id] || 0;
          const remainingNeeded = totalNeeded - totalSent;
          return remainingNeeded > 0 ? remainingNeeded : 0;
        },
      },
      {
        field: "shippingQuantity",
        headerName: t("sentQuantity"),
        flex: 0.08,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <div>{(productionsSent[params.row.Material.id] || []).length}</div>
          );
        },
      },
      {
        field: "productions",
        headerName: t("availableProductions"),
        flex: 0.12,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const totalNeeded = params.row.quantity;
          const totalAlreadySent =
            totalSentByMaterial[params.row.Material.id] || 0;
          const neededQuantity = totalNeeded - totalAlreadySent;
          const totalSent = (productionsSent[params.row.Material.id] || [])
            .length;
          const selectedProductions =
            productionsSent[params.row.Material.id] || [];
          const options = (productions[params.row.Material.id] || []).filter(
            (option) =>
              !selectedProductions.find((selected) => selected.id === option.id)
          );
          const labelFormat =
            params.row.Material.type !== "material" ? "" : "default";

          if (options.length === 0) {
            return (
              <div
                style={{
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
              >
                {t("noProductionAvailable")}
              </div>
            );
          }
          return (
            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleClickOpen(params.row.Material.id)}
                disabled={neededQuantity <= totalSent}
                sx={{
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                {t("selectOptions")}
              </Button>
              <SelectOptionsDialog
                open={open}
                handleClose={handleClose}
                params={params}
                options={options}
                selectedProductions={selectedProductions}
                setProductionsSent={setProductionsSent}
                productionsSent={productionsSent}
                neededQuantity={neededQuantity}
                totalSent={totalSent}
                labelFormat={labelFormat}
              />
            </div>
          );
        },
      },
      {
        field: "selectedProductions",
        headerName: t("selectedProductions"),
        flex: 0.3,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const selectedProductions = (
            productionsSent[params.row.Material.id] || []
          )
            .map((production) => {
              return `(${production.batchNumber}) ${production.reference} - ${production.comment}`;
            })
            .join(", ");
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              <div style={{ marginRight: "0.5rem", flex: 1 }}>
                {selectedProductions}
              </div>
              {(productionsSent[params.row.Material.id] || []).length > 0 && (
                <CloseIcon
                  onClick={() => {
                    setProductionsSent({
                      ...productionsSent,
                      [params.row.Material.id]: [],
                    });
                  }}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "darkblue",
                    borderRadius: "50%",
                    fontSize: "0.9rem",
                  }}
                />
              )}
            </div>
          );
        },
      },
    ];

    if (rows.length === 0) {
      return <div></div>;
    }

    return (
      <DataGrid
        slots={{
          noRowsOverlay: NoRowOverlay,
          loadingOverlay: LinearProgress,
        }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 30 },
          },
        }}
        pageSizeOptions={[30, 50]}
        disableSelectionOnClick
        hideFooter
        autoHeight
        rowHeight={80}
        onEditCellChangeCommitted={(params) => {
          if (
            params.field === "shippingQuantity" &&
            isNaN(Number(params.props.value))
          ) {
            alert("Please enter a valid number");
          }
        }}
      />
    );
  };

  const handleSaveDraft = async () => {
    try {
      let details = `${numPackages} nb of pkg, `;
      packages.forEach((pkg, index) => {
        details += `pkg ${index + 1}: ${pkg.length} l, ${pkg.height} h, ${
          pkg.width
        } w, ${pkg.volumetricWeight} vw; `;
      });
      const newShipment = {
        draftData: productionsSent,
        status: "draft",
        sendingDate: sendingDate,
        packingSlip: packingSlip,
        carrier: carrier,
        carrierOrderDate: carrierOrderDate,
        preparationDate: preparationDate,
        shippedQuantity: shippedQuantity,
        comment: comment,
        draftOrder: orderId,
        shippedBy: selectedUser !== "" ? selectedUser : user,
        packageDetails: details || "",
      };
      const draftShipment = await getDraftShipmentByOrderId(orderId);
      if (draftShipment) {
        for (const [field, value] of Object.entries(newShipment)) {
          await updateShipment(draftShipment.id, field, value);
        }
      } else {
        await createShipment(orderId, newShipment, details, user);
      }
      showSuccessAlert("Draft saved successfully");
      navigate("/order", {
        state: { order, orderId, centerNumber, studyCode, studyId, centerId },
      });
    } catch (error) {
      showErrorAlert("An error occurred while creating the shipment");
      console.log("Error creating shipment", error);
    }
  };
  // fetch order
  React.useEffect(() => {
    store.dispatch(getOrder(orderId)).then(() => setIsLoading(false));
  }, [orderId]);

  // Fetch available productions
  React.useEffect(() => {
    if (order) {
      order.Supplies.forEach((supply) => {
        store
          .dispatch(getProductionsByMaterial(supply.Material.id))
          .then((productions) => {
            setProductions((prevProductions) => ({
              ...prevProductions,
              [supply.Material.id]: productions,
            }));
          });
      });
    }
  }, [order]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "auto" }}>
          <OrderTable
            order={order}
            studyCode={studyCode}
            centerNumber={centerNumber}
          />
          <ReadOnlyTextField
            title={t("shippingAddress").toUpperCase()}
            value={fullAddress}
            multiline
          />

          <Box sx={{ p: 2 }}>
            <div style={{ width: "100%" }}>
              <TextField
                value={t("materialOrder").toUpperCase()}
                InputProps={{
                  readOnly: true,
                }}
                inputProps={{
                  style: { textAlign: "center" },
                }}
                fullWidth
              />
              <CustomDataGrid
                rows={materialsTypeMaterial}
                headerName={t("material").toUpperCase()}
              />
              <CustomDataGrid
                rows={materialsTypeSupply}
                headerName={t("supply").toUpperCase()}
              />
              <CustomDataGrid
                rows={materialsTypeReagent}
                headerName={t("reagent").toUpperCase()}
              />
              <CustomDataGrid
                rows={materialsTypeDocuments}
                headerName={t("document").toUpperCase()}
              />
            </div>
          </Box>
        </div>
        <Box sx={{ p: 2 }}>
          <TextField
            value={t("shippingInformation").toUpperCase()}
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: { textAlign: "center" },
            }}
            fullWidth
          />

          <Grid container spacing={2} style={{ marginTop: "12px" }}>
            <CustomDatePicker
              label={t("preparationDate")}
              name="preparationDate"
              value={preparationDate}
              onChange={setPreparationDate}
            />
            <CustomDatePicker
              label={t("carrierOrderDate")}
              name="carrierOrderDate"
              value={carrierOrderDate}
              onChange={setCarrierOrderDate}
            />
            <CustomDatePicker
              label={t("sendingDate")}
              name="sendingDate"
              value={sendingDate}
              onChange={setSendingDate}
            />
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>{t("carrier")}</InputLabel>
                <Select
                  label={t("carrier")}
                  value={carrier}
                  onChange={(e) => setCarrier(e.target.value)}
                  name="carrier"
                >
                  <MenuItem value="Area Time">Area Time</MenuItem>
                  <MenuItem value="GoPak">GoPak</MenuItem>
                  <MenuItem value="Marken">Marken</MenuItem>
                  <MenuItem value="QuickSTAT">QuickSTAT</MenuItem>
                  <MenuItem value="Transcourrier">Transcourrier</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("airwaybill")}
                name="packingSlip"
                value={packingSlip}
                onChange={(e) => setPackingSlip(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("numberOfPackages")}
                type="number"
                name="numPackages"
                value={numPackages}
                onChange={handleNumPackagesChange}
                fullWidth
                inputProps={{ min: 0 }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginTop: "8px" }}
          >
            {Array.from({ length: numPackages }, (_, index) => (
              <HandlePackageDetails
                key={index}
                index={index}
                packageData={packages[index]}
                packages={packages}
                setPackages={setPackages}
              />
            ))}
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              id="comment"
              name="comment"
              label={t("comment")}
              multiline
              rows={3}
              sx={{ m: 2 }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              fullWidth
            />
          </Grid>
          {isAdmin && (
            <Grid item xs={4}>
              <Box sx={{ m: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="shipped-by-label">
                    {t("shippedBy")}
                  </InputLabel>
                  <Select
                    labelId="shipped-by-label"
                    id="shipped-by"
                    value={selectedUser}
                    onChange={handleChange}
                    label="Shipped By"
                  >
                    {cdlUsers.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.name} {user.firstname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveDraft}
            style={{ padding: "16px 88px", margin: "16px" }}
          >
            {t("saveDraft")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNewShipment}
            style={{ padding: "16px 88px", margin: "16px" }}
          >
            {t("sendShippingInfo")}
          </Button>
        </Grid>
        {showErrorMessage && (
          <p
            style={{
              color: "#721c24",
              backgroundColor: "#f8d7da",
              padding: "10px",
              borderRadius: "4px",
              margin: "16px",
              marginTop: 0,
              textAlign: "center",
            }}
          >
            {t("fillRequiredFields")}
          </p>
        )}
      </Paper>
    </Grid>
  );
};
