import React from 'react';
import { CustomTextField } from '../../ui/CustomTextField';
import { useTranslation } from 'react-i18next';

const AddressForm = ({
  centerNumber,
  hospital,
  setHospital,
  ward,
  setWard,
  building,
  setBuilding,
  street,
  setStreet,
  floor,
  setFloor,
  zipcode,
  setZipcode,
  phone,
  setPhone,
  name,
  setName,
  email,
  setEmail,
  fieldErrors,
  center
}) => {
  const { t } = useTranslation();

  return (
    <>
      <CustomTextField
        name="centerNumber"
        label={t("centerNumber")}
        value={centerNumber}
        readOnly
      />
      <CustomTextField
        name="hospital"
        label={t("hospital")}
        value={hospital}
        onChange={(e) => setHospital(e.target.value)}
        error={!!fieldErrors.hospital}
        helperText={fieldErrors.hospital}
      />
      <CustomTextField
        name="ward"
        label={t("ward")}
        value={ward}
        required={false}
        onChange={(e) => setWard(e.target.value)}
      />
      <CustomTextField
        name="building"
        label={t("building")}
        value={building}
        required={false}
        onChange={(e) => setBuilding(e.target.value)}
      />
      <CustomTextField
        name="street"
        label={t("street")}
        value={street}
        onChange={(e) => setStreet(e.target.value)}
        error={!!fieldErrors.street}
        helperText={fieldErrors.street}
      />
      <CustomTextField
        name="floor"
        label={t("floor")}
        value={floor}
        required={false}
        onChange={(e) => setFloor(e.target.value)}
      />
      <CustomTextField
        name="zipcode"
        label={t("zipcode")}
        value={zipcode}
        onChange={(e) => setZipcode(e.target.value)}
        error={!!fieldErrors.zipcode}
        helperText={fieldErrors.zipcode}
      />
      <CustomTextField
        name="city"
        label={t("city")}
        value={center?.city}
        readOnly
        InputLabelProps={{ shrink: true }}
      />
      <CustomTextField
        name="country"
        label={t("country")}
        value={center?.country}
        readOnly
        InputLabelProps={{ shrink: true }}
      />
      <CustomTextField
        name="phone"
        label={t("phoneNumber")}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        error={!!fieldErrors.phone}
        helperText={fieldErrors.phone}
      />
      <CustomTextField
        name="recipientName"
        label={t("recipientName")}
        value={name}
        onChange={(e) => setName(e.target.value)}
        error={!!fieldErrors.name}
        helperText={fieldErrors.name}
      />
      <CustomTextField
        name="email"
        label={t("email")}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!fieldErrors.email}
        helperText={fieldErrors.email}
      />
    </>
  );
};

export default AddressForm;