import React from 'react';
import { TextField, Grid, InputAdornment } from '@mui/material';

const ReadOnlyField = ({ label, type, name, value, adornment, onDoubleClick = () => {} }) => {
  return (
    <Grid item xs={4}>
      <div onDoubleClick={onDoubleClick}>
        <TextField
          label={label}
          type={type}
          name={name}
          value={value}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: adornment ? <InputAdornment position="end">{adornment}</InputAdornment> : null,
          }}
          fullWidth
          disabled
        />
      </div>
    </Grid>
  );
};

export default ReadOnlyField;