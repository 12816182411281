import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid, Paper } from "@mui/material";
import LocalizedDataGrid from "../../ui/datagrid/LocalizedDataGrid";
import LinearProgress from "@mui/material/LinearProgress";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const SPACING = 50;

export const ScreenSampleShipments = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const studyId = location.state?.studyId;
  const studyCode = location.state?.studyCode;
  const centerId = location.state?.centerId;
  const centerNumber = location.state?.centerNumber;
  const isLoading = useSelector((state) => state.center.isLoadingCenters);
  
  console.log("studyId", studyId);
  const samples = [
    {
      id: 1,
      number: "0033",
      centerNumber: "0022",
      city: "SAN FRANCISCO",
      patientCode: "0102, 0116",
      sendingDate: "08/01/2024",
      carrier: "Quickstat",
      airwaybill: "204590164W",
      shippingTemperature: "Dry Ice",
      deliveryDate: "12/01/2024",
      laboratory: "Quality Assistance",
      status: "Sent to 3rd lab",
      timePickupToDelivery: "4",
      conformity: "YES",
      comment: "",
    },
    {
      id: 2,
      number: "0034",
      centerNumber: "0018",
      city: "SIOUX FALLS",
      patientCode: "0110",
      sendingDate: "15/01/2024",
      carrier: "Quickstat",
      airwaybill: "204590144W",
      shippingTemperature: "Dry Ice",
      deliveryDate: "22/01/2024",
      laboratory: "Quality Assistance",
      status: "Sent to 3rd lab",
      timePickupToDelivery: "7",
      conformity: "YES",
      comment: "",
    },
    {
      id: 3,
      number: "0035",
      centerNumber: "0001",
      city: "CHICAGO",
      patientCode: "00925, 0109",
      sendingDate: "23/01/2024",
      carrier: "Quickstat",
      airwaybill: "204730376W",
      shippingTemperature: "Dry Ice",
      deliveryDate: "26/01/2024",
      laboratory: "Quality Assistance",
      status: "Sent to 3rd lab",
      timePickupToDelivery: "3",
      conformity: "YES",
      comment: "",
    },
    {
      id: 4,
      number: "0036",
      centerNumber: "0002",
      city: "CHAPEL HILL",
      patientCode: "0103, 0112, 0113, 0115",
      sendingDate: "05/02/2024",
      carrier: "Quickstat",
      airwaybill: "204850614W",
      shippingTemperature: "Dry Ice",
      deliveryDate: "09/02/2024",
      laboratory: "Quality Assistance",
      status: "Sent to 3rd lab",
      timePickupToDelivery: "4",
      conformity: "YES",
      comment: "",
    },
  ];

  const columns = [
    {
      field: "number",
      headerName: t("number"),
      minWidth: 100,
    },
    {
      field: "centerNumber",
      headerName: t("center"),
      minWidth: 100,
    },
    {
      field: "city",
      headerName: t("city"),
      minWidth: 150,
      renderCell: (params) =>
        params.value
          .split(" ")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" "),
    },
    {
      field: "patientCode",
      headerName: t("patientCode"),
      minWidth: 200,
    },
    {
      field: "sendingDate",
      headerName: t("sendingDate"),
      minWidth: 130,
    },
    {
      field: "carrier",
      headerName: t("carrier"),
      minWidth: 130,
    },
    {
      field: "airwaybill",
      headerName: t("airwaybill"),
      minWidth: 130,
    },
    {
      field: "shippingTemperature",
      headerName: t("shippingTemperature"),
      minWidth: 130,
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {t("shippingTemperature")}
        </div>
      ),
    },
    {
      field: "deliveryDate",
      headerName: t("deliveryDate"),
      minWidth: 130,
    },
    {
      field: "laboratory",
      headerName: t("laboratory"),
      minWidth: 150,
    },
    {
      field: "status",
      headerName: t("status"),
      minWidth: 150,
    },
    {
      field: "timePickupToDelivery",
      headerName: t("timePickupToDelivery"),
      minWidth: 130,
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {t("timePickupToDelivery")}
        </div>
      ),
    },
    {
      field: "conformity",
      headerName: t("conformity"),
      minWidth: 100,
    },
    {
      field: "comment",
      headerName: t("comment"),
      minWidth: 150,
    },
  ];

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "85vh" }}>
          <LocalizedDataGrid
            disableRowSelectionOnClick
            slots={{
              noRowsOverlay: NoRowOverlay,
              loadingOverlay: LinearProgress,
            }}
            rows={samples}
            columns={columns}
            loading={isLoading}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[20, 50]}
            checkboxSelection
            rowHeight={40}
          />
        </div>
      </Paper>
      {centerId && (
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => {
            navigate("/sample-shipments/new", {
              state: { centerId, studyId, centerNumber, studyCode },
            });
          }}
          style={{ position: "absolute", right: SPACING, bottom: SPACING }}
        >
          <AddIcon />
        </Fab>
      )}
    </Grid>
  );
};
