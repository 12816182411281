import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { CustomTextField } from "../../ui/CustomTextField";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LinearProgress from "@mui/material/LinearProgress";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import { useSelector } from "react-redux";
import UserInfo from "../../ui/UserInfo";
import { createOrder, createSupply } from "../../../services/orderService";
import {
  showErrorAlert,
  showSuccessAlert,
  showLoadingAlert,
  closeLoadingAlert,
} from "../../ui/utils/AlertUtils";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export const ScreenConfirmOrderForm = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const isAdmin = user.role === "admin";
  const orderData = location.state?.orderData;
  const materials = location.state.materials;
  const centerId = location.state?.centerId;
  const studyId = location.state?.studyId;
  const centerNumber = location.state?.centerNumber;
  const studyCode = location.state?.studyCode;

  if (!orderData) {
    return null;
  }

  const handleEditOrder = () => {
    navigate("/orders/new", {
      state: {
        orderData,
        materials,
        studyId,
        centerId,
        centerNumber,
        studyCode,
      },
    });
  };

  const handleConfirmOrder = async () => {
    showLoadingAlert("Your order is being created...");
    try {
      // Create the order
      const orderResponse = await createOrder({
        status: "placed",
        orderDate: orderData.orderDate
        ? dayjs(orderData.orderDate, "DD/MM/YYYY").hour(12).minute(0).second(0).toDate()
        : new Date(),
        UserId: user.id,
        centerId: centerId,
        totalQuantity: materials.reduce(
          (total, material) => total + material.quantity,
          0
        ),
        hospital: orderData.hospital,
        street: orderData.street,
        zipcode: orderData.zipcode,
        floor: orderData.floor,
        building: orderData.building,
        ward: orderData.ward,
        contactName: orderData.name,
        contactEmail: orderData.email,
        comment: orderData.comment,
        contactPhone: orderData.phone,
        isFirstSupply: orderData.isFirstSupply,
        isDemokit: orderData.isDemokit,
        shippingAddress: orderData.city + ", " + orderData.country,
      });

      // Get the created order's id to link the supplies
      const orderId = orderResponse.id;
      for (const material of materials) {
        await createSupply({
          materialId: material.id,
          quantity: material.quantity,
          orderId: orderId,
        });
      }
      closeLoadingAlert();
      showSuccessAlert(t("orderCreatedSuccess"));
      navigate(`/order`, {
        state: { orderId, studyId, centerNumber, centerId, studyCode },
      });
    } catch (error) {
      closeLoadingAlert();
      showErrorAlert(t("orderCreationError"));
      console.error("An error occurred while creating the order:", error);
    }
  };

  const materialsTypeMaterial = materials.filter(
    (material) => material.type === "material"
  );
  const materialsTypeSupply = materials.filter(
    (material) => material.type === "supply"
  );
  const materialsTypeReagent = materials.filter(
    (material) => material.type === "reagent"
  );
  const materialsTypeDocuments = materials.filter(
    (material) => material.type === "document"
  );

  const CustomDataGrid = ({ rows, headerName, disabled }) => {
    const columns = [
      {
        field: "name",
        headerName: headerName,
        flex: 0.8,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "quantity",
        headerName: t("quantity"),
        flex: 0.2,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        editable: !disabled,
      },
    ];

    if (rows.length === 0) {
      return <div></div>;
    }

    return (
      <DataGrid
        slots={{
          noRowsOverlay: NoRowOverlay,
          loadingOverlay: LinearProgress,
        }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 30 },
          },
        }}
        rowHeight={40}
        pageSizeOptions={[30, 50]}
        disableSelectionOnClick
        hideFooter
        autoHeight
        disableColumnMenu
        isRowSelectable={() => !disabled}
      />
    );
  };

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Box sx={{ p: 2 }}>
          <TextField
            value={t("shippingAddress").toUpperCase()}
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: { textAlign: "center" },
            }}
            fullWidth
            style={{ marginBottom: "16px" }}
          />

          <Grid container spacing={2}>
            {Object.entries(orderData).map(([key, value]) => {
              if (
                [
                  "centerId",
                  "comment",
                  "isFirstSupply",
                  "isDemokit",
                  "orderDate",
                ].includes(key)
              ) {
                return null;
              }

              return (
                <CustomTextField
                  key={key}
                  label={t(key)}
                  name={key}
                  defaultValue={value}
                  disabled
                />
              );
            })}
          </Grid>
        </Box>
        {/* ADD the materials */}
        <Box sx={{ p: 2 }}>
          <div style={{ width: "100%" }}>
            <TextField
              value={t("materialOrder").toUpperCase()}
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            <CustomDataGrid
              rows={materialsTypeMaterial}
              headerName={t("material").toUpperCase()}
              disabled
            />
            <CustomDataGrid
              rows={materialsTypeSupply}
              headerName={t("supply").toUpperCase()}
              disabled
            />
            <CustomDataGrid
              rows={materialsTypeReagent}
              headerName={t("reagent").toUpperCase()}
              disabled
            />
            <CustomDataGrid
              rows={materialsTypeDocuments}
              headerName={t("document").toUpperCase()}
              disabled
            />
          </div>
        </Box>

        <Box sx={{ p: 2 }}>
          {orderData.comment !== "" && (
            <TextField
              id="comment"
              name="comment"
              label="Comment"
              fullWidth
              multiline
              rows={4}
              sx={{ mb: 2 }}
              value={orderData.comment}
              readOnly
              disabled
            />
          )}
          {isAdmin && (
            <Box sx={{ p: 2 }}>
              <Grid container alignItems="center" spacing={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={orderData.isFirstSupply}
                      name="isFirstSupply"
                    />
                  }
                  label={t("firstSupply")}
                  disabled
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={orderData.isDemokit} name="isDemoKit" />
                  }
                  label={t("demoKit")}
                  disabled
                />
                <Grid item xs={4}>
                  {orderData.orderDate && (
                    <TextField
                      label={t("orderDate")}
                      name="orderDate"
                      value={orderData.orderDate}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ width: "100%" }}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
          <UserInfo
            title={t("personPlacingOrder").toUpperCase()}
            user={user}
            date={new Date().toLocaleDateString()}
          />
          <Grid container item xs={12} justifyContent={"space-between"}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEditOrder}
              style={{ padding: "16px 88px" }}
            >
              {t("editOrder")}
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ padding: "16px 88px" }}
              onClick={handleConfirmOrder}
            >
              {t("confirmOrder")}
            </Button>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};
