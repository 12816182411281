export const ROOT = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT: "LOGOUT",
  UPDATE_TOKEN: "UPDATE_TOKEN",
};

export const USER = {
  SET_USER: "UPDATE_USER",
  CLEAN_USER: "CLEAN_USER",
  USER_GET_ALL: "USER_GET_ALL",
  USER_GET_ALL_FAILURE: "USER_GET_ALL_FAILURE",
  USER_GET_ALL_SUCCESS: "USER_GET_ALL_SUCCESS",
  USER_CREATE: "USER_CREATE",
  USER_CREATE_FAILURE: "USER_CREATE_FAILURE",
  USER_CREATE_SUCCESS: "USER_CREATE_SUCCESS",
  USER_UPDATE: "USER_UPDATE",
  USER_UPDATE_FAILURE: "USER_UPDATE_FAILURE",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  USER_CHANGE_PASSWORD: "USER_CHANGE_PASSWORD",
  USER_CHANGE_PASSWORD_FAILURE: "USER_CHANGE_PASSWORD_FAILURE",
  USER_CHANGE_PASSWORD_SUCCESS: "USER_CHANGE_PASSWORD_SUCCESS",
};

export const CENTER = {
  CENTER_GET_ALL: "CENTER_GET_ALL",
  CENTER_GET_ALL_FAILURE: "CENTER_GET_ALL_FAILURE",
  CENTER_GET_ALL_SUCCESS: "CENTER_GET_ALL_SUCCESS",
  CENTER_GET_ONE: "CENTER_GET_ONE",
  CENTER_GET_ONE_FAILURE: "CENTER_GET_ONE_FAILURE",
  CENTER_GET_ONE_SUCCESS: "CENTER_GET_ONE_SUCCESS",
  CENTER_CREATE: "CENTER_CREATE",
  CENTER_CREATE_FAILURE: "CENTER_CREATE_FAILURE",
  CENTER_CREATE_SUCCESS: "CENTER_CREATE_SUCCESS",
  CENTER_UPDATE: "CENTER_UPDATE",
  CENTER_UPDATE_FAILURE: "CENTER_UPDATE_FAILURE",
  CENTER_UPDATE_SUCCESS: "CENTER_UPDATE_SUCCESS",
  CENTER_DELETE: "CENTER_DELETE",
  CENTER_DELETE_FAILURE: "CENTER_DELETE_FAILURE",
  CENTER_DELETE_SUCCESS: "CENTER_DELETE_SUCCESS",
};

export const SPONSOR = {
  SPONSOR_GET_ALL: "SPONSOR_GET_ALL",
  SPONSOR_GET_ALL_FAILURE: "SPONSOR_GET_ALL_FAILURE",
  SPONSOR_GET_ALL_SUCCESS: "SPONSOR_GET_ALL_SUCCESS",
  SPONSOR_CREATE: "SPONSOR_CREATE",
  SPONSOR_CREATE_FAILURE: "SPONSOR_CREATE_FAILURE",
  SPONSOR_CREATE_SUCCESS: "SPONSOR_CREATE_SUCCESS",
  SPONSOR_UPDATE: "SPONSOR_UPDATE",
  SPONSOR_UPDATE_FAILURE: "SPONSOR_UPDATE_FAILURE",
  SPONSOR_UPDATE_SUCCESS: "SPONSOR_UPDATE_SUCCESS",
};

export const PRODUCTION = {
  PRODUCTION_GET_ALL: "PRODUCTION_GET_ALL",
  PRODUCTION_GET_ALL_FAILURE: "PRODUCTION_GET_ALL_FAILURE",
  PRODUCTION_GET_ALL_SUCCESS: "PRODUCTION_GET_ALL_SUCCESS",
  PRODUCTION_CREATE: "PRODUCTION_CREATE",
  PRODUCTION_CREATE_FAILURE: "PRODUCTION_CREATE_FAILURE",
  PRODUCTION_CREATE_SUCCESS: "PRODUCTION_CREATE_SUCCESS",
  PRODUCTION_UPDATE: "PRODUCTION_UPDATE",
  PRODUCTION_UPDATE_FAILURE: "PRODUCTION_UPDATE_FAILURE",
  PRODUCTION_UPDATE_SUCCESS: "PRODUCTION_UPDATE_SUCCESS",
  PRODUCTION_DELETE: "PRODUCTION_DELETE",
  PRODUCTION_DELETE_FAILURE: "PRODUCTION_DELETE_FAILURE",
  PRODUCTION_DELETE_SUCCESS: "PRODUCTION_DELETE_SUCCESS",
};

export const STUDY = {
  STUDY_GET_ALL: "STUDY_GET_ALL",
  STUDY_GET_ALL_FAILURE: "STUDY_GET_ALL_FAILURE",
  STUDY_GET_ALL_SUCCESS: "STUDY_GET_ALL_SUCCESS",
  STUDY_GET_ONE: "STUDY_GET_ONE",
  STUDY_GET_ONE_FAILURE: "STUDY_GET_ONE_FAILURE",
  STUDY_GET_ONE_SUCCESS: "STUDY_GET_ONE_SUCCESS",
  STUDY_CREATE: "STUDY_CREATE",
  STUDY_CREATE_FAILURE: "STUDY_CREATE_FAILURE",
  STUDY_CREATE_SUCCESS: "STUDY_CREATE_SUCCESS",
  STUDY_UPDATE: "STUDY_UPDATE",
  STUDY_UPDATE_FAILURE: "STUDY_UPDATE_FAILURE",
  STUDY_UPDATE_SUCCESS: "STUDY_UPDATE_SUCCESS",
  STUDY_CLEAR: "STUDY_CLEAR",
};

export const ORDER = {
  ORDER_GET_ALL: "ORDER_GET_ALL",
  ORDER_GET_ALL_FAILURE: "ORDER_GET_ALL_FAILURE",
  ORDER_GET_ALL_SUCCESS: "ORDER_GET_ALL_SUCCESS",
  ORDER_CREATE: "ORDER_CREATE",
  ORDER_CREATE_FAILURE: "ORDER_CREATE_FAILURE",
  ORDER_CREATE_SUCCESS: "ORDER_CREATE_SUCCESS",
  ORDER_GET_ONE: "ORDER_GET_ONE",
  ORDER_GET_ONE_FAILURE: "ORDER_GET_ONE_FAILURE",
  ORDER_GET_ONE_SUCCESS: "ORDER_GET_ONE_SUCCESS",
  ORDER_UPDATE: "ORDER_UPDATE",
  ORDER_UPDATE_FAILURE: "ORDER_UPDATE_FAILURE",
  ORDER_UPDATE_SUCCESS: "ORDER_UPDATE_SUCCESS",
};

export const MATERIAL = {
  MATERIAL_GET_ALL: "MATERIAL_GET_ALL",
  MATERIAL_GET_ALL_FAILURE: "MATERIAL_GET_ALL_FAILURE",
  MATERIAL_GET_ALL_SUCCESS: "MATERIAL_GET_ALL_SUCCESS",
  MATERIAL_CREATE: "MATERIAL_CREATE",
  MATERIAL_CREATE_FAILURE: "MATERIAL_CREATE_FAILURE",
  MATERIAL_CREATE_SUCCESS: "MATERIAL_CREATE_SUCCESS",
  MATERIAL_EDIT: "MATERIAL_EDIT",
  MATERIAL_EDIT_FAILURE: "MATERIAL_EDIT_FAILURE",
  MATERIAL_EDIT_SUCCESS: "MATERIAL_EDIT_SUCCESS",
  MATERIAL_DELETE: "MATERIAL_DELETE",
  MATERIAL_DELETE_FAILURE: "MATERIAL_DELETE_FAILURE",
  MATERIAL_DELETE_SUCCESS: "MATERIAL_DELETE_SUCCESS",
};

export const ANALYSIS = {
  ANALYSIS_GET_ALL: "ANALYSIS_GET_ALL",
  ANALYSIS_GET_ALL_FAILURE: "ANALYSIS_GET_ALL_FAILURE",
  ANALYSIS_GET_ALL_SUCCESS: "ANALYSIS_GET_ALL_SUCCESS",
  ANALYSIS_CREATE: "ANALYSIS_CREATE",
  ANALYSIS_CREATE_FAILURE: "ANALYSIS_CREATE_FAILURE",
  ANALYSIS_CREATE_SUCCESS: "ANALYSIS_CREATE_SUCCESS",
  ANALYSIS_UPDATE: "ANALYSIS_UPDATE",
  ANALYSIS_UPDATE_FAILURE: "ANALYSIS_UPDATE_FAILURE",
  ANALYSIS_UPDATE_SUCCESS: "ANALYSIS_UPDATE_SUCCESS",
  ANALYSIS_DELETE: "ANALYSIS_DELETE",
  ANALYSIS_DELETE_FAILURE: "ANALYSIS_DELETE_FAILURE",
  ANALYSIS_DELETE_SUCCESS: "ANALYSIS_DELETE_SUCCESS",
  ANALYSIS_GET_ONE: "ANALYSIS_GET_ONE",
  ANALYSIS_GET_ONE_FAILURE: "ANALYSIS_GET_ONE_FAILURE",
  ANALYSIS_GET_ONE_SUCCESS: "ANALYSIS_GET_ONE_SUCCESS",
};

