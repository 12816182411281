import React from "react";
import { Grid, Paper, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UserInfo from "../../ui/UserInfo";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import ReadOnlyTextField from "../../ui/ReadOnlyTextField";
import { useTranslation } from "react-i18next";
import AddressContactInfo from "../../ui/AddressContactInfo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CustomDatePicker from "../../ui/utils/CustomDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
printSampleShipmentOrder
} from "../../ui/SamplePdfGenerator";

const analyses = [
  {
    id: 1,
    name: "PK for Irinotecan",
    visitIds: ["C1D1", "C1D3", "C1D15", "Additional C1D14"],
    shippingTemperature: "Dry Ice",
    description: "1 tube /visit",
    destination: "TPL",
    primary: true,
  },
  {
    id: 2,
    name: "PK for Oxaliplatin",
    visitIds: ["C1D1", "C1D3", "C1D15", "Additional C1D14"],
    shippingTemperature: "4°C",
    description: "2 tubes per timepoint",
    destination: "CDL",
    primary: false,
  },
  {
    id: 3,
    name: "Plasma",
    visitIds: ["C1D9", "C2D1", "C4D1"],
    shippingTemperature: "Room Temperature",
    description: "1 tube /visit",
    destination: "TPL",
    primary: true,
  },
  {
    id: 4,
    name: "PBMC",
    visitIds: ["C1D9", "C2D1", "C4D1"],
    shippingTemperature: "Dry Ice",
    description: "2 tubes per timepoint",
    destination: "CDL",
    primary: true,
  },
  {
    id: 5,
    name: "MRD",
    visitIds: ["C1D9", "C2D1", "C4D1"],
    shippingTemperature: "4°C",
    description: "1 tube /visit",
    destination: "TPL",
    primary: true,
  },
  {
    id: 6,
    name: "BBMC",
    visitIds: ["C1D9", "C2D1", "C4D1"],
    shippingTemperature: "Room Temperature",
    description: "2 tubes per timepoint",
    destination: "CDL",
    primary: true,
  },
];

export const ScreenSampleShipmentSummary = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.user);
  const adminUser = user.role === "admin";
  const savedData = location.state.savedData;
  const fullAddress = AddressContactInfo({ order: savedData.address });
  const [status, setStatus] = React.useState("Pending CDL Approval");
  const [airwaybill, setAirwaybill] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState("");
  const [dateOfShipment, setDateOfShipment] = React.useState(savedData.expectedShippingDate);
  const [startTimeOfShipment, setStartTimeOfShipment] = React.useState(dayjs());
  const [endTimeOfShipment, setEndTimeOfShipment] = React.useState(dayjs().add(3, 'hour'));
  const [timeError, setTimeError] = React.useState("");

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleConfirmClick = () => {
    console.log("Confirm clicked");
    setStatus("Sample Shipment Order Confirmed");

    const shipmentData = {
      savedData,
      file: selectedFile,
      airwaybill,
      dateOfShipment,
      startTimeOfShipment : dayjs(startTimeOfShipment).format("HH:mm"),
      endTimeOfShipment : dayjs(endTimeOfShipment).format("HH:mm"),
      status,
    };

    navigate("/sample-shipments/confirmation-form", {
      state: { shipmentData },
    });
  };

  const handleImportClick = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file.name);
      console.log("File selected:", file);
      //  save file logic
    }
  };

  console.log(savedData);

  return (
    <Grid container justifyContent="center">
      <Paper
        elevation={3}
        style={{ padding: "20px", width: "80%", marginTop: "20px" }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <TextField
                label={t("shipmentNumber")}
                value={savedData.shipmentNumber}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("status")}
                value={status}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Button
              variant="contained"
              color="primary"
              onClick={() => {
                printSampleShipmentOrder({data: savedData, address: fullAddress} );
              }}
              style={{ padding: "16px 32px", margin: "16px" }}
            >
              {t("sampleShipmentOrder")}
            </Button>
        </Box>
        <ReadOnlyTextField
          title={t("shippingAddress").toUpperCase()}
          value={fullAddress}
          multiline
          maxRows={4}
        />
        <Box sx={{ p: 2 }}>
          <TextField
            value={t("samplesToShip").toUpperCase()}
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: { textAlign: "center" },
            }}
            fullWidth
            sx={{ mt: 1, mb: 1 }}
          />
          {savedData.patients &&
            savedData.patients.map((patient, patientIndex) => (
              <section
                key={patientIndex}
                style={{
                  marginBottom: "16px",
                  backgroundColor: "#f5f5f5",
                  padding: "10px",
                }}
                id="patient-section"
              >
                <h3>Patient ID {patient.selectedPatient}</h3>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Analysis</TableCell>
                        <TableCell>Primary or Backup</TableCell>
                        <TableCell>Visits</TableCell>
                        <TableCell>Destination</TableCell>
                        <TableCell>Shipping Temperature</TableCell>
                        <TableCell>Total Number of Samples</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {patient.analyses &&
                        patient.analyses.map((analysis, analysisIndex) => (
                          <TableRow key={analysisIndex}>
                            <TableCell>
                              {
                                analyses.find(
                                  (a) => a.id === analysis.selectedAnalysis
                                )?.name
                              }
                            </TableCell>
                            <TableCell>{analysis.analysisType}</TableCell>
                            <TableCell>
                              {analysis.selectedVisits.join(", ")}
                            </TableCell>
                            <TableCell>
                              {
                                analyses.find(
                                  (a) => a.id === analysis.selectedAnalysis
                                )?.destination
                              }
                            </TableCell>
                            <TableCell>
                              {
                                analyses.find(
                                  (a) => a.id === analysis.selectedAnalysis
                                )?.shippingTemperature
                              }
                            </TableCell>
                            <TableCell>{analysis.totalSamples}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </section>
            ))}
          <TextField
            value={t("transportInformation").toUpperCase()}
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: { textAlign: "center" },
            }}
            fullWidth
            sx={{ mt: 2 }}
          />
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <p>
                <strong>Expected Shipping Date:</strong>{" "}
                {formatDate(savedData.expectedShippingDate)} between{" "}
                {savedData.pickUpSampleStartTime} and{" "}
                {savedData.pickUpSampleEndTime}
              </p>
            </Grid>
            <Grid item xs={6}>
              <p>
                <strong>Carrier:</strong> {savedData.carrier}
              </p>
            </Grid>
          </Grid>

          {adminUser && (
            <Grid
              container
              spacing={2}
              sx={{ mb: 2, border: "2px solid #c9c9c9", alignItems: "center" }}
            >
              <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label={t("airwaybillNumber")}
                  value={airwaybill}
                  onChange={(e) => setAirwaybill(e.target.value)}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  style={{ padding: "16px" }}
                >
                  {selectedFile ? selectedFile : t("importAirwaybill")}
                  <input
                    type="file"
                    accept=".pdf,.jpeg,.jpg,.png"
                    hidden
                    onChange={handleImportClick}
                  />
                </Button>
                {!selectedFile && (
                  <Typography variant="body2" color="textSecondary">
                    (Accepted formats: .pdf, .jpeg, .jpg, .png)
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Pick up date and time:
                </Typography>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={1} sx={{ ml: 1 }}>
                  <CustomDatePicker
                    label={t("dateOfShipment")}
                    value={dateOfShipment}
                    minDate={new Date()}
                    onChange={(date) => setDateOfShipment(date)}
                  />

                  <Grid item xs={3}>
                    <TimePicker
                      label={t("startTime")}
                      value={startTimeOfShipment}
                      onChange={(time) => setStartTimeOfShipment(time)}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!timeError,
                          helperText: timeError,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TimePicker
                      label={t("endTime")}
                      value={endTimeOfShipment}
                      onChange={(time) => setEndTimeOfShipment(time)}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!timeError,
                          helperText: timeError,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>

              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmClick}
                  style={{ padding: "16px 88px", margin: "16px" }}
                >
                  {t("confirm")}
                </Button>
              </Grid>
            </Grid>
          )}
          <UserInfo
            title={t("personRequestingShipment").toUpperCase()}
            user={savedData.user}
            date={new Date().toLocaleDateString()}
          />
        </Box>
      </Paper>
    </Grid>
  );
};
